import $ from 'jquery';

let activeStylesheetName = 'vtx-css';
const selectorToggleHighContrast = '.toggle-accessibility-stylesheet';

function getMenuSelectedStylesheet(checkedElement) {
  if ($(checkedElement).prop('checked')) {
    return 'high_contrast';
  }
  return 'vtx-css';
}
function setMenuSelectedStylesheet(stylesheet) {
  if ('high_contrast' === stylesheet) {
    $.each($(selectorToggleHighContrast), function (i, toggle) {
      $(toggle).prop('checked', true);
    });
  }
}

function updateContrastInHtml(stylesheet) {
  if ('high_contrast' === stylesheet) {
    $('html').addClass('high-contrast');
  } else {
    $('html').removeClass('high-contrast');
  }
}

function saveUserStylesheet() {
  localStorage.setItem('activeStylesheetName', activeStylesheetName);
}
function loadSavedUserStylesheet() {
  activeStylesheetName =
    null !== localStorage.getItem('activeStylesheetName')
      ? localStorage.getItem('activeStylesheetName')
      : activeStylesheetName;
}

function initToggleHighContrast() {
  setMenuSelectedStylesheet(activeStylesheetName);
  $(selectorToggleHighContrast).on('change', function (e) {
    activeStylesheetName = getMenuSelectedStylesheet(e.currentTarget);
    saveUserStylesheet();

    updateContrastInHtml(activeStylesheetName);
  });
}

// Early call to restore the state from the LocalStorage
loadSavedUserStylesheet();

updateContrastInHtml(activeStylesheetName);

window.addEventListener('DOMContentLoaded', function () {
  initToggleHighContrast();
});
