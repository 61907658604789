import $ from 'jquery';

// eslint-disable-next-line no-undef
const vtxAccessibility = VTXACCESSIBILITY || {};

let activeFontSize = 'font-size-normal';
const selectorChangeFontSize = '.btn-change-font-size';

function getMenuSelectedFontSize(checkedElement) {
  return $(checkedElement).data('font-size-modifier');
}
function setMenuSelectedFontSize(fontSize) {
  $(selectorChangeFontSize).prop('checked', false);
  $.each(
    $(selectorChangeFontSize + '[data-font-size-modifier="' + fontSize + '"]'),
    function (i, radio) {
      $(radio).prop('checked', true);
    }
  );
}

function updateFontSizeInHtml() {
  $('html')
    .removeClass(
      Object.keys(vtxAccessibility.font_size.font_size_names).join(' ')
    )
    .addClass(activeFontSize);
}

function saveUserFontSize() {
  localStorage.setItem('activeFontSize', activeFontSize);
}
function loadSavedUserFontSize() {
  activeFontSize =
    null !== localStorage.getItem('activeFontSize')
      ? localStorage.getItem('activeFontSize')
      : activeFontSize;
}

function initGlobalFontSize() {
  setMenuSelectedFontSize(activeFontSize);
  $(selectorChangeFontSize).on('change', function (e) {
    activeFontSize = getMenuSelectedFontSize(e.currentTarget);
    saveUserFontSize();
    updateFontSizeInHtml();
  });
}

// Early call to restore the state from the LocalStorage
loadSavedUserFontSize();
updateFontSizeInHtml();

window.addEventListener('DOMContentLoaded', function () {
  initGlobalFontSize();
});
